<template>
  <div>
    <b-modal
      id="modal-edit-tahapan"
      centered
      title="Edit Data Tahapan Seleksi"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="4">
          <template v-slot:label> Status Tahapan </template>
          <b-form-select
            v-model="data2.statusTahapan"
            :options="status"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="4">
          <template v-slot:label> Keterangan </template>
          <b-form-textarea
            v-model="data2.keteranganPoolTahapan"
            type="text"
            v-on:keydown.enter.prevent="simpan()"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group label="Kirim Notifikasi Email" label-cols-md="4">
          <b-form-checkbox
            v-model="notif"
            id="checkbox-2"
            name="checkbox-2"
            value="1"
            unchecked-value="0"
            class="mt-2"
          >
          </b-form-checkbox>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-edit-tahapan')"
        >
          Batal
        </b-button>
        <b-button variant="primary" @click="simpan()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
export default {
  name: "modalEdit",
  props: ["data2", "edit2"],
  data() {
    return {
      busy: false,
      button: "Simpan",
      status: [
        { value: 0, text: "Sedang Proses" },
        { value: 1, text: "Lolos" },
        { value: 2, text: "Tidak Lolos" },
      ],
      notif: "0",
    };
  },
  watch: {
    edit2: function (newVal, oldVal) {
      if (oldVal != newVal) {
        console.log(this.data2);
      }
    },
  },
  methods: {
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data2.id = vm.data2.poolTahapanId;
      vm.data2.notif = vm.notif;
      // console.log(vm.data2, "ini data2");
      axios
        .post(ipBackend + "poolTahapan/update", vm.data2)
        .then((res) => {
          console.log(res, "ini ress");
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH STATUS TAHAPAN",
              showing: true,
              statusNya: vm.data2.statusTahapan,
            });
            this.$bvModal.hide("modal-edit-tahapan");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            this.$bvModal.hide("modal-edit-tahapan");
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          this.$bvModal.hide("modal-edit-tahapan");
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
