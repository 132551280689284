<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Data Pelamar Yang Masuk Tahap Seleksi</strong>
              </h5>
            </template>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Atur Tahap Seleksi'"
                      v-b-modal.modal-input-tahapan
                      @click="(data = item.item), (edit = !edit)"
                      ><CIcon name="cil-settings" />{{ item.actions }}</b-button
                    >

                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail'"
                      v-b-modal.modal-detail
                      @click="(data = item.item), (detail = !detail)"
                      ><CIcon name="cil-info" />{{ item.actions }}</b-button
                    >

                    <b-button
                      variant="success"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Terima Sebagai Karyawan'"
                      v-b-modal.modal-terima
                      @click="(dataPelamar = item.item), (terima = !terima)"
                      ><CIcon name="cil-check" />{{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <modal-input-panggilan
      :data="data"
      :tahapan="tahapan"
      :edit="edit"
      @alertFromChild="triggerAlert($event), getDatas()"
      @refresh="refresh($event)"
    />

    <modal-detail :data="data" :detail="detail" />
    <modal-terima
      :dataPelamar="dataPelamar"
      :terima="terima"
      @alertFromChild="triggerAlert($event)"
    />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalInputPanggilan from "../../../component/hcms/tahapSeleksiPelamar/modalInput.vue";
import modalDetail from "../../../component/hcms/tahapSeleksiPelamar/modalDetail.vue";
import modalTerima from "../../../component/hcms/tahapSeleksiPelamar/modalTerima.vue";

export default {
  components: {
    modalInputPanggilan,
    modalDetail,
    modalTerima,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      data: "",
      edit: false,
      detail: false,
      terima: false,
      dataPelamar: "",
      items: [],
      tahapan: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaPengirim",
          label: "Nama Pelamar",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamatPengirim",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "emailPengirim",
          label: "Email",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "posisiDilamar",
          label: "Posisi Yang Dilamar",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getTahapan();
    this.getDatas();
    // this.getKebutuhan();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      this.tableBusy = true;
      let listPelamar = await axios.get(
        ipBackend + "postLoker/listByStatus/" + 2
      );
      this.items = listPelamar.data.data;
      // FOREACH diganti FOR
      // this.items.forEach((element, index) => {
      //   let x = this.items[index];
      //   x.no = index + 1;
      //   x.posisiDilamar = `${x.namaLoker} - (Posisi : ${x.namaPosisi})`;
      // });
      // console.log(this.items, "ini");
      for (let i = 0; i < this.items.length; i++) {
        let x = this.items[i];
        x.no = i + 1;
        x.posisiDilamar = `${x.namaLoker} - (Posisi : ${x.namaPosisi})`;
      }

      this.totalRows = this.items.length;
      this.tableBusy = false;
    },
    async getTahapan() {
      this.tableBusy = true;
      let listTahapan = await axios.get(ipBackend + "masterTahapan/list");

      this.tahapan = listTahapan.data.data;
    },
    refresh(event) {
      let vm = this;
      if (event.statusNya == 2) {
        vm.showing = true;
        vm.variant = "warning";
        vm.msg = "BERHASIL MENGUPDATE DATA";
        vm.getDatas();
        setTimeout(() => {
          vm.showing = false;
        }, 4000);
      }
    },
    triggerAlert(event) {
      let vm = this;
      // vm.showing = event.showing;
      // vm.variant = event.variant;
      // vm.msg = event.msg;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
    },
  },
};
</script>
