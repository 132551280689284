<template>
  <div>
    <b-modal
      id="modal-terima"
      centered
      title="Terima Sebagai Karyawan"
      header-bg-variant="success"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tanggal Mulai Kerja" label-cols-md="4">
          <date-picker
            style="width: 100%"
            :state="checkIfValid('tanggalMulaiKerja')"
            v-model="$v.data.tanggalMulaiKerja.$model"
          ></date-picker>
        </b-form-group>

        <!-- <b-form-group label="Tanggal Gajian" label-cols-md="4">
          <b-form-input
            type="number"
            :state="checkIfValid('tanggalGaji')"
            v-model="$v.data.tanggalGaji.$model"
            placeholder="Silahkan Isi dari 1 sampai 31"
          ></b-form-input>
        </b-form-group> -->

        <!-- <b-form-group label="Status Karyawan" label-cols-md="4">
          <b-form-select
            :options="status"
            :state="checkIfValid('statusKerjaKaryawan')"
            v-model="$v.data.statusKerjaKaryawan.$model"
            @change="show()"
          ></b-form-select>
        </b-form-group>

        <b-form-group
          v-if="data.statusKerjaKaryawan == 0"
          label="Lama Kontrak (Bulan)"
          label-cols-md="4"
        >
          <b-form-select
            :options="bulan"
            :state="checkIfValid('lamaKontrakKaryawan')"
            v-model="$v.data.lamaKontrakKaryawan.$model"
          ></b-form-select>
        </b-form-group> -->
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-terima')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import {
  required,
  requiredIf,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
  },
  name: "modalTerima",
  props: ["dataPelamar"],
  data() {
    return {
      busy: false,
      button: "Simpan",

      data: {
        tanggalMulaiKerja: "",
        // statusKerjaKaryawan: null,
        // lamaKontrakKaryawan: null,
        postLokerId: "",
        // tanggalGaji: null,
      },
      status: [
        { value: 0, text: "Kontrak" },
        { value: 1, text: "Permanen" },
      ],
      bulan: [
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
        { value: 5, text: "5" },
        { value: 6, text: "6" },
        { value: 7, text: "7" },
        { value: 8, text: "8" },
        { value: 9, text: "9" },
        { value: 10, text: "10" },
        { value: 11, text: "11" },
        { value: 12, text: "12" },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      tanggalMulaiKerja: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    show() {},
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data.postLokerId = vm.dataPelamar.postLokerId;
      axios
        .post(ipBackend + "dataKaryawan/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENERIMA KARYAWAN",
              showing: true,
            });
            vm.data.tanggalMulaiKerja = null
            vm.$v.$reset()
            vm.$bvModal.hide("modal-terima");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
