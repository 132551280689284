<template>
  <div>
    <b-modal
      id="modal-detail"
      size="lg"
      centered
      title="Detail Tahapan Seleksi Pelamar"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-row no-gutters>
        <b-col cols="12" md="3" lg="3">
          <div
            style="
              width: 100%;
              height: 200px;
              background-color: #ebedef;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
            class="shadow-lg"
          >
            <img src="img/avatars/user.png" class="c-avatar-img" />
          </div>
        </b-col>
        <b-col cols="12" md="9" lg="9">
          <div style="padding-left: 30px">
            <b-table-simple borderless striped small>
              <b-tbody v-if="data2.length">
                <b-tr>
                  <b-td style="width: 30%">Nama</b-td>
                  <b-td style="width: 2.5%">:</b-td>
                  <b-td>{{ data2[0].namaPelamar }}</b-td>
                </b-tr>

                <b-tr>
                  <b-td>Alamat</b-td>
                  <b-td>:</b-td>
                  <b-td>{{ data2[0].alamatPelamar }}</b-td>
                </b-tr>

                <b-tr>
                  <b-td>Email</b-td>
                  <b-td>:</b-td>
                  <b-td>{{ data2[0].emailPelamar }}</b-td>
                </b-tr>

                <b-tr>
                  <b-td>No. Telepon</b-td>
                  <b-td>:</b-td>
                  <b-td>{{ data2[0].noHpPelamar }}</b-td>
                </b-tr>

                <b-tr>
                  <b-td>Posisi</b-td>
                  <b-td>:</b-td>
                  <b-td>{{ data.posisiDilamar }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </b-col>
      </b-row>

      <b-row no-gutters v-for="item in listTahapan" :key="item.poolTahapanId">
        <b-col cols="12" md="3" lg="3">
          <div style="width: 100%; positon: relative; background-color: ">
            <div class="line-timeline"></div>
            <center>
              <div
                :class="[
                  item.statusTahapan == 0 ? kuning : '',
                  item.statusTahapan == 2 ? merah : '',
                  item.statusTahapan == 1 ? ijo : '',
                ]"
              >
                <h4 class="mt-0 mb-0" style="line-height: 1">
                  <strong>{{ item.hari }}</strong>
                </h4>
                <h6 class="mt-0 mb-0" style="font-size: 12px; line-height: 1">
                  <strong>{{ item.bulan }}</strong>
                </h6>
                <h6 class="mt-0 mb-0" style="line-height: 1; font-size: 12px">
                  <strong>{{ item.tahun }}</strong>
                </h6>
              </div>
            </center>
          </div>
        </b-col>

        <b-col cols="12" md="9" lg="9">
          <div class="outerbox-timeline">
            <div class="insidebox-timeline shadow-lg">
              <h5 class="mt-0 mb-0">
                <strong>{{ item.namaTahapan }}</strong>
              </h5>
              <h6 class="mt-0 mb-0">
                {{ item.keteranganPoolTahapan }}
              </h6>
            </div>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id"
export default {
  name: "modalDetail",
  props: ["data", "detail"],
  data() {
    return {
      listTahapan: [],
      data2: [],
      merah: "merah",
      kuning: "kuning",
      ijo: "ijo",
    };
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.getDetail();
      }
    },
  },
  methods: {
    async getDetail() {
      let vm = this;
      let id = vm.data.postLokerId;
      let listnya = await axios.get(
        ipBackend + "postLoker/detailListByPostLokerId/" + id
      );
      vm.data2 = listnya.data.data;
      vm.data.namaPelamar = vm.data2.namaPelamar;
      vm.data.alamatPelamar = vm.data2.alamatPelamar;
      vm.data.emailPelamar = vm.data2.emailPelamar;
      vm.data.noHpPelamar = vm.data2.noHpPelamar;
      vm.listTahapan = listnya.data.data[0].tahapan;
      console.log(vm.listTahapan,'ini');
      // FOREACH diganti FOR
      // vm.listTahapan.forEach((element, index) => {
      //   let x = vm.listTahapan[index];
      //   x.hari = moment(x.tanggalPemanggilan).format("DD");
      //   x.bulan = moment(x.tanggalPemanggilan).format("MMM");
      //   x.tahun = moment(x.tanggalPemanggilan).format("YYYY");
      // });
      for (let i = 0; i < vm.listTahapan.length; i++) {
        let x = vm.listTahapan[i]
        x.hari = moment(x.tanggalPemanggilan).format("DD");
        x.bulan = moment(x.tanggalPemanggilan).format("MMM");
        x.tahun = moment(x.tanggalPemanggilan).format("YYYY");
      }
      vm.listTahapan.sort(function (a, b) {
        return moment(a.createdAt) - moment(b.createdAt);
      });
      vm.$bvModal.show("modal-detail");
    },
  },
};
</script>

<style>
.line-timeline {
  width: 5px;
  height: 100%;
  background-color: #6c6e7e;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 0;
}

.ijo {
  width: 80px;
  height: 80px;
  background-color: #1cbd3f;
  border-radius: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  border: 5px solid #6c6e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.kuning {
  width: 80px;
  height: 80px;
  background-color: #ffe607;
  border-radius: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  border: 5px solid #6c6e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.merah {
  width: 80px;
  height: 80px;
  background-color: #e03838;
  border-radius: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  border: 5px solid #6c6e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.circle-timeline {
  width: 80px;
  /* height: 100%; */
  background-color: #ebedef;
  border-radius: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  z-index: 1;
  border: 1px solid #6c6e7e;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.outerbox-timeline {
  width: 100%;
  /* height: 100%; */

  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
}

.insidebox-timeline {
  width: 100%;
  /* height: 70px; */
  background-color: #ebedef;
  /* position: absolute; */
  left: -40px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
  border: 1px solid #6c6e7e;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 10px;
  flex-direction: column;
}
</style>
