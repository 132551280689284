<template>
  <div>
    <b-modal
      id="modal-input-tahapan"
      size="lg"
      centered
      title="Atur Tahapan Seleksi"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Tahapan" label-cols-md="3">
          <multiselect
            :state="checkIfValid('tahapanId')"
            v-model="$v.dataTahapan.tahapanId.$model"
            :disabled="false"
            :options="tahapan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih Tahapan --"
            label="namaTahapan"
            track-by="id"
            @input="itikiwir(dataTahapan.tahapanId)"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Tanggal" label-cols-md="3">
          <date-picker
            :state="checkIfValid('tanggalPemanggilan')"
            v-model="$v.dataTahapan.tanggalPemanggilan.$model"
            style="width: 100%"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Jam" label-cols-md="3">
          <b-form-timepicker
            :state="checkIfValid('jamPemanggilan')"
            v-model="$v.dataTahapan.jamPemanggilan.$model"
            locale="id"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group label="Keterangan" label-cols-md="3">
          <b-form-textarea
            type="text"
            v-model="dataTahapan.keteranganPoolTahapan"
            style="width: 100%"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Kirim Notifikasi Email" label-cols-md="3">
          <b-form-checkbox
            v-model="dataTahapan.notif"
            id="checkbox-1"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
            class="mt-2"
          >
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <b-button
            @click="createTahapan"
            variant="primary"
            :disabled="!isValid"
            >Simpan</b-button
          >
        </b-form-group>
      </b-form>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <hr />
        </b-col>
      </b-row>

      <b-alert dismissible fade :show="showing" :variant="variant">{{
        msg
      }}</b-alert>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row>
            <b-col md="3">
              <b-form-group
                label="Per Halaman"
                label-for="per-page-select"
                label-cols-md="7"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="md"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6" offset-md="3">
              <b-form-group
                label="Cari"
                label-for="filter-input"
                label-cols-md="3"
                label-align-md="right"
                label-size="md"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Ketik disini untuk mencari ..."
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                      variant="danger"
                      >Hapus</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="listTahapan"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                stacked="md"
                responsive
                show-empty
                small
                @filtered="onFiltered"
                bordered
                striped
                hover
              >
                <template #cell(no)="item">
                  {{ item.index + 1 }}
                </template>

                <template #cell(actions)="item">
                  <b-button
                    variant="warning"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Edit'"
                    v-b-modal.modal-edit-tahapan
                    @click="(data2 = item.item), (edit2 = !edit2)"
                    ><CIcon name="cil-pencil" />{{ item.actions }}</b-button
                  >
                  <!-- <b-button
                    variant="danger"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Hapus'"
                    v-b-modal.modal-delete
                    ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                  > -->
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5" offset-md="7">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-tahapan')"
        >
          Batal
        </b-button>
      </template>
    </b-modal>

    <modal-edit-tahapan
      :data2="data2"
      :edit2="edit2"
      @alertFromChild="triggerAlert($event), getListTahapan()"
    />
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import modalEditTahapan from "../../../component/hcms/tahapSeleksiPelamar/modalEditTahapan.vue";
import moment from "moment";
import "moment/locale/id"
export default {
  name: "modalInput",
  props: ["data", "tahapan", "edit"],
  components: {
    Multiselect,
    DatePicker,
    modalEditTahapan,
  },
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      data2: "",
      edit2: false,
      dataTahapan: {
        masterTahapanId: "",
        tanggalPemanggilan: "",
        keteranganPoolTahapan: "",
        postLokerId: "",
        tahapanId: "",
        jamPemanggilan: "",
        notif: "0",
      },

      // notif: [
      //   { value: 0, text: "Tidak" },
      //   { value: 1, text: "Ya" },
      // ],

      listTahapan: [],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaTahapan",
          label: "Tahapan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tglPemanggilan",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "jamPemanggilan",
          label: "Jam",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-3 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    formString() {
      return JSON.stringify(this.dataTahapan, null, 4);
    },
    isValid() {
      return !this.$v.dataTahapan.$invalid;
    },
    isDirty() {
      return this.$v.dataTahapan.$anyDirty;
    },
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.getListTahapan();
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    dataTahapan: {
      tahapanId: {
        required,
      },
      tanggalPemanggilan: {
        required,
      },
      jamPemanggilan: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataTahapan[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    itikiwir(x) {},
    async getListTahapan() {
      let vm = this;
      let id = vm.data.postLokerId;
      let listnya = await axios.get(
        ipBackend + "poolTahapan/listTahapanByPostLokerId/" + id
      );
      vm.listTahapan = listnya.data.data;
      // FOREACH diganti FOR
      // vm.listTahapan.forEach((element, index) => {
      //   let x = vm.listTahapan[index];
      //   x.tglPemanggilan = moment(x.tanggalPemanggilan).format("LL");
      //   if (x.statusTahapan == 0) {
      //     x.status = "Sedang Proses";
      //   } else if (x.statusTahapan == 1) {
      //     x.status = "Lolos";
      //   } else if (x.statusTahapan == 2) {
      //     x.status = "Tidak Lolos";
      //   }
      // });
      console.log(listnya.data.data);
      for (let i = 0; i < vm.listTahapan.length; i++) {
        let x = vm.listTahapan[i];
        x.tglPemanggilan = moment(x.tanggalPemanggilan).format("LL");
        if (x.statusTahapan == 0) {
          x.status = "Sedang Proses";
        } else if (x.statusTahapan == 1) {
          x.status = "Lolos";
        } else if (x.statusTahapan == 2) {
          x.status = "Tidak Lolos";
        }
      }
      vm.listTahapan.sort(function (a, b) {
        console.log(moment(a.createdAt), moment(b.createdAt));
        return moment(a.createdAt) - moment(b.createdAt);
      });
      this.totalRows = this.listTahapan.length;
    },
    createTahapan() {
      let vm = this;
      vm.dataTahapan.masterTahapanId = vm.dataTahapan.tahapanId.id;
      vm.dataTahapan.postLokerId = vm.data.postLokerId;
      vm.dataTahapan.tanggalPemanggilan = moment(
        vm.dataTahapan.tanggalPemanggilan
      );
      // console.log(vm.dataTahapan, "ini datatahapan");
      axios
        .post(ipBackend + "poolTahapan/register", vm.dataTahapan)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.showing = true;
            vm.variant = "success";
            vm.msg = "BERHASIL MENAMBAHKAN TAHAPAN";
            vm.getListTahapan();
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
            vm.dataTahapan.tahapanId = "";
            vm.dataTahapan.jamPemanggilan = "";
            vm.dataTahapan.tanggalPemanggilan = "";
            vm.dataTahapan.keteranganPoolTahapan = "";
            vm.$v.$reset();
          } else {
            vm.button = "Simpan";
            vm.showing = true;
            vm.variant = "danger";
            vm.msg = _.toUpper(res.data.message);
            setTimeout(() => {
              vm.showing = false;
            }, 4000);
            // vm.$emit("alertFromChild", {
            //   variant: "danger",
            //   msg: _.toUpper(res.data.message),
            //   showing: true,
            // });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.showing = true;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
          // vm.$emit("alertFromChild", {
          //   variant: "danger",
          //   msg: "TERJADI KESALAHAN PADA SERVER",
          //   showing: true,
          // });
        });
    },
    triggerAlert(event) {
      let vm = this;
      if (event.statusNya == 2) {
        vm.$emit("refresh", {
          variant: event.variant,
          msg: event.msg,
          showing: event.showing,
          statusNya: event.statusNya,
        });
        this.$bvModal.hide("modal-input-tahapan");
      } else {
        vm.showing = event.showing;
        vm.variant = event.variant;
        vm.msg = event.msg;
        vm.getListTahapan();
        setTimeout(() => {
          vm.showing = false;
        }, 4000);
      }
    },
  },
};
</script>
